import {DisputeHandlingStatus, FileRead} from "../../../declarations";
import {Chip} from "../Chip";
import {InputUtils} from "../../../helpers";
import {Text} from "../Text";
import {Colors} from "../../colors";
import {map} from "lodash";
import {Link} from "@mui/material";
import React from "react";

const getDisputeChip = (handlingStatus: DisputeHandlingStatus) => {
  const handlingStatusString = InputUtils.prettierSnakeCase(handlingStatus);
  if (handlingStatus === "pending") {
    return <Chip label={handlingStatusString} variant_={"warning"} border size={"medium"} />;
  }
  if (handlingStatus === "closed") {
    return <Chip label={handlingStatusString} variant_={"success"} border size={"medium"} />;
  }
  if (handlingStatus === "action_required") {
    return <Chip label={handlingStatusString} variant_={"error"} border size={"medium"} />;
  } else {
    return <Chip label={handlingStatusString} variant_={"inherit"} border size={"medium"} />;
  }
};

interface DisputeFilesListProps {
  files?: FileRead[];
  title: string;
  handleViewFile: ({
    fileId,
    fileName,
    accountId,
    userId,
  }: {
    fileId: string;
    fileName: string;
    accountId?: string;
    userId?: string;
  }) => void;
}

const DisputeFilesList = ({files, title, handleViewFile}: DisputeFilesListProps) => {
  if (!files) {
    return null;
  }

  return (
    <Text>
      <Text size={12} fontWeight="medium" color={Colors.grey._400}>
        {title}
      </Text>
      {map(files, (file, index) => {
        return (
          <Link
            key={file.id}
            sx={{textDecoration: "none"}}
            onClick={() =>
              handleViewFile({
                fileId: file.id,
                fileName: file.filename,
                userId: file.user_id,
                accountId: file.account_id,
              })
            }
          >
            <Text
              size={14}
              sx={{
                whiteSpace: "break-spaces",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {file.filename}
            </Text>
          </Link>
        );
      })}
    </Text>
  );
};

export {getDisputeChip, DisputeFilesList};
