import React from "react";
import {Button, Container, Text} from "../../index";
import {IconDocument} from "../../../assets";

interface IFilePreview extends IFilesPreview {
  file: File;
}
interface IFilesPreview {
  files: File[];
  onChange: (event: any) => void;
  isDialog?: boolean;
  isMobile?: boolean;
  isAgent?: boolean;
  rowLength?: string;
}
const getFileRowWidth = (isMobile: boolean, isAgent: boolean, isDialog: boolean) => {
  if (isMobile && isDialog) return "180px";
  if ((isMobile && !isDialog) || (isAgent && isDialog)) return "200px";
  return "320px";
};
const FilePreview = ({file, files, onChange, isDialog, isMobile = false, isAgent = true, rowLength}: IFilePreview) => {
  const handleRemove = (fileName: string) => {
    const filteredFiles = files.filter((file) => file.name !== fileName);
    onChange(filteredFiles);
  };

  return (
    <Container row sx={{justifyContent: "space-between"}}>
      <Container row width={rowLength ?? getFileRowWidth(isMobile, isAgent, isDialog || false)}>
        <IconDocument />
        <Text
          size={14}
          fontWeight={"medium"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {file.name}
        </Text>
      </Container>
      <Button buttonType={"outlined-secondary"} size={"small"} onClick={() => handleRemove(file.name)}>
        Remove
      </Button>
    </Container>
  );
};

export const FilesPreview = ({files, onChange, isDialog, isMobile, isAgent, rowLength}: IFilesPreview) => {
  return (
    <Container sx={{alignItems: "start", gap: "5px"}}>
      {files.map((file: File, key: number) => {
        return (
          <FilePreview
            key={key}
            file={file}
            files={files}
            onChange={onChange}
            isDialog={isDialog}
            isMobile={isMobile}
            isAgent={isAgent}
            rowLength={rowLength}
          />
        );
      })}
    </Container>
  );
};
